<template>
  <div>
    <div class="content-row" v-if="bannerImage">
      <div class="content-layout-fixer">
        <img :src="bannerImage" alt="banner image" />
      </div>
    </div>
    <div class="content-row">
      <div class="content-layout-fixer">
        <VueHeadline class="brands-headline" level="1" weightLevel="3">{{ category }}</VueHeadline>
        <div class="brands">
          <div class="brand" v-for="(brand, id) in brands" :key="id">
            <div
              class="logo"
              :style="{
                backgroundImage: `url(${brand.image}`,
              }"
            ></div>
            <div class="right-area">
              <VueHeadline level="3" weightLevel="6" color="grey-40" class="headline-text">{{
                brand.name
              }}</VueHeadline>
              <VueText sizeLevel="3" color="grey-30" class="text" v-html="brand.description">
              </VueText>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-row grey-bg brand-news-wrapper" v-if="news.length > 0">
      <div class="content-layout-fixer">
        <VueHeadline class="products-headline" level="1" weightLevel="3"
          >Marka Haberleri</VueHeadline
        >
        <BrandContentList :contents="news" @showMoreClicked="showMore" :showButton="getMoreNews">
        </BrandContentList>
      </div>
    </div>
  </div>
</template>

<script>
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import BrandContentList from '@/components/brand/BrandContentList/BrandContentList.vue';
import { Content } from '@/services/Api/index';
import { maxBy } from '@/mixins/arrayUtils.js';
import { Pladis } from '@/services/Api/index.js';

export default {
  name: 'BrandUlker',
  components: {
    VueHeadline,
    VueText,
    BrandContentList,
  },
  data() {
    return {
      bannerImage: null,
      brands: [],
      category: '',
      news: [],
      getMoreNews: true,
      isDescToggled: false,
      lastIndex: 0,
      lastId: 0,
      pageSize: 3,
    };
  },
  mounted() {
    this.getProducts(this.$route.params.id);
  },
  created() {
    this.getContents();
  },
  computed: {
    icons() {
      return ICON_VARIABLES;
    },
  },
  methods: {
    getProducts(id) {
      Pladis.getProductsByCategoryId(id).then(response => {
        if (response.data && response.data.Data) {
          const { products, category, bannerImage } = response.data.Data;
          this.brands = products;
          this.category = category;
          this.bannerImage = bannerImage;
          this.$route.meta.breadcrumb[2].name = category.toLowerCase();
          let product_names = [];
          let product_ids = [];
          let product_brands = [];
          products.forEach((value, index) => {
            product_brands[index] = 'ÜLKER';
            product_names[index] = value.name;
            product_ids[index] = value.order;
          });
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').category_id_hierarchy = '2 > ' + id;
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').category_name_hierarchy =
            'Ülker > ' + category;
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').category_path = window.location.pathname;
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').order_currency = 'TRY';
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').product_brands = product_brands;
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').product_names = product_names;
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').product_ids = product_ids;
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').trade_category = 'ÜLKER';
          window.dataLayer
            .slice()
            .reverse()
            .find(i => i.event === 'commercial_data').trade_sub_category = category;
        }
      });
    },
    toggleDesc() {
      this.isDescToggled = !this.isDescToggled;
    },
    showMore() {
      this.lastIndex = this.news.length;
      this.lastId = maxBy(this.news, 'feedId');
      this.pageSize = 9;
      this.getContents(true);
    },
    getContents(isNextPage) {
      Content.getPladisBrandNews(this.lastId, this.lastIndex, this.pageSize, 236).then(res => {
        const {
          Data: { contents, isFeedContinue },
        } = res.data;
        this.getMoreNews = isFeedContinue;
        if (isNextPage) {
          this.news.push(...contents);
        } else {
          this.news = contents;
        }
        if (this.news.length) {
          this.lastIndex = this.news.length;
          this.lastId = this.news[this.lastIndex - 1].feedId;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.content-row {
  height: unset;
  padding-left: 20px;
}
.gray-bg {
  background: #f8f9f9;
  margin-bottom: 60px;
}
.head {
  display: flex;
  padding: 40px 0 50px 0;
}
.right-area {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 18px;
  width: 100%;

  .headline-text {
    margin-bottom: palette-space-level(10);
  }
  .text {
    line-height: 1.27;
    padding-right: palette-space-level(10);
  }

  .link-holder {
    display: flex;
    flex-direction: row;
    align-items: space-between;

    .left-link {
      padding-right: palette-space-level(30);
    }
    .left-link,
    .right-link {
      padding-top: palette-space-level(10);
      line-height: 1.27;
      text-decoration: underline;
    }
  }
}
.people-wrapper {
  margin-top: 18px;
}
.grid-row {
  gap: 20px;
}
.products-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 21px;
  grid-row-gap: 40px;
}
.products-headline {
  @media screen and (min-width: 1400px) {
    padding-left: 10px;
  }
}
/deep/ .content-card-list {
  padding-left: 0;
  @media screen and (min-width: 1400px) {
    padding-left: 10px;
  }
  margin-top: 0;
}
/deep/ .product {
  &-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }
  &-img {
    width: 100%;
    height: 235px;
    padding: 37px 15px;
    border: 1px solid palette-color-level('grey', '20');
    border-radius: palette-radius-level('2');

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &-name {
    padding: palette-space-level('10') 0;
    text-align: center;
    font-size: 24px;
  }
}
.brands-headline {
  margin: palette-space-level(30) 0 palette-space-level(20);
  @media screen and (min-width: 1400px) {
    padding-left: 10px;
  }
}
.brands {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  .brand {
    display: flex;
    flex: calc(50% - 10px);
    max-width: calc(50% - 20px);
    @media screen and (min-width: 1400px) {
      padding-left: 10px;
    }
  }
  .logo {
    background-position: center;
    background-size: 150px;
    background-repeat: no-repeat;
    background-color: palette-color-level('white', '100');
    min-width: 170px;
    height: 170px;
    border: 1px solid palette-color-level('grey', '20');
    border-radius: palette-radius-level('1');
  }

  .text {
    line-height: 1.27;
    padding-right: 0;
  }
}
.brand-news-wrapper {
  margin-top: palette-space-level(40);
}
</style>
